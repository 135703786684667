body {
  margin: 40px 0px 0px 0px;
}

.header-container, .main {
  margin: 0px 118px;
}

a {
  text-decoration: none;
  outline: none;
  color: black;
}

a:hover {
  text-decoration: 2px underline #c28b6d;
  text-underline-offset: 4px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-container-nav {
  font-weight: bold;
}
.header-container-nav-accueil {
  margin-right: 18px;
}
.header-container-navLogo {
  display: flex;
  align-items: center;
}
.header-container-navLogo-slogan {
  font-weight: bold;
}
.header-container-navLogo-slogan p {
  margin: 0;
}

.banner-container-img {
  width: 100%;
  border-radius: 40px;
}

.main-card {
  margin-top: 50px;
}

.card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 250px;
}
.card-img {
  width: 50%;
}
.card-img img {
  width: 100%;
  border-radius: 40px;
}
.card-descriptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 700px;
}
.card-descriptions h2 {
  margin: 0;
  font-size: 2.5em;
  font-weight: 600;
}
.card-descriptions p, .card-descriptions ul {
  font-size: 1.5em;
}
.card:nth-child(even) {
  flex-direction: row-reverse;
}
.card:nth-last-child(-n+2) {
  margin-bottom: 100px;
}

.main-offre {
  display: flex;
  flex-direction: column;
}
.main-offre-containerTitle, .main-offre-containerParagraphe {
  display: flex;
  justify-content: center;
}
.main-offre-containerTitle h2 {
  font-size: 4.5em;
  background: #ddc1b2;
}
.main-offre-containerParagraphe {
  flex-direction: column;
}
.main-offre-containerParagraphe-paragraphe {
  width: 44%;
  margin-top: 0px;
}
.main-offre-containerParagraphe-list {
  padding: 0;
  width: 44%;
}
.main-offre-containerParagraphe-paragraphe, .main-offre-containerParagraphe-list {
  font-size: 1.5em;
}
.main-offre-img {
  position: relative;
  top: -224px;
}
.main-offre-img img {
  width: 100%;
}

.container-amenagement {
  display: flex;
}
.container-amenagement img {
  width: 100%;
}

.contact {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}
.contact-link, .contact-title {
  display: flex;
  justify-content: center;
}
.contact-link h2, .contact-title h2 {
  font-size: 4.5em;
}
.contact-link-mail, .contact-title-mail {
  font-weight: bold;
  font-size: 2em;
  padding: 34px;
  border: 3px solid black;
  border-radius: 100%;
}

li {
  list-style-type: none;
}

.footer {
  display: flex;
  justify-content: center;
  background-color: #be886c;
}
.footer ul {
  padding: 0;
}
.footer-container-link {
  display: flex;
}
.footer-container-link-logo {
  margin-right: 10px;
}
.footer-container-link-link {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.2em;
  color: black;
}

.linkedin {
  margin-right: 12px;
}


