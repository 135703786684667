li {
    list-style-type: none;
}

.footer {
    display: flex;
    justify-content: center;
    background-color: $color-footer;
    & ul {
        padding: 0;
    }
    &-container-link {
        display: flex;
        &-logo {
            margin-right: 10px;
        }
    }
    &-container-link-link {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 1.2em;
        color: black;
    }
}

.linkedin {
    margin-right: 12px;
}