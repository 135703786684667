.main-offre {
  display: flex;
  flex-direction: column;
  &-containerTitle,
  &-containerParagraphe {
    display: flex;
    justify-content: center;
  }
  &-containerTitle h2 {
        font-size: 4.5em;
          background: $color-offre;
  }
  &-containerParagraphe {
    flex-direction: column;
    &-paragraphe {
        width: 44%;
        margin-top: 0px;
      }
    &-list {
      padding: 0;
      width: 44%;
    }
    &-paragraphe , &-list {
      font-size: 1.5em;
    }
  }
    &-img{
  position: relative;
  top: -224px;
    & img{
      width: 100%;
    }
  }
}