.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-nav {
        font-weight: bold;
        &-accueil {
            margin-right: 18px;
        }
    }
    &-navLogo {
        display: flex;
        align-items: center;

        &-slogan {
            font-weight: bold;
            & p {
                margin: 0;
            }
        }
    }
}