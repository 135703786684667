.container-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FF6060;
}

.container-title {
    margin-bottom: 66px;
}

.title-error {
    font-size: 288px;
    font-weight: 700;
    margin: 0;
}

.container-content {
    margin-bottom: 182px;
}

.content-error {
    font-size: 36px;
    font-weight: 500;
    margin: 0;
    text-align: center;
}

.container-link {
    margin-bottom: 159px;
}

.container-link a {
    color: #FF6060;
    font-size: 18px;
    margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 425px) {

    .main-error {
        margin: auto 15.625%;
    }

    .container-title {
        margin-bottom: 11px;
    }

    .title-error {
        font-size: 96px;
        font-weight: 700;
    }

    .container-content {
        margin-bottom: 133px;
    }

    .content-error {
        font-size: 18px;
        font-weight: 500;
    }

    .container-link a {
        font-size: 14px;
    }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
    .main-error {
        margin: auto 15.625%;
    }

    .container-title {
        margin-bottom: 11px;
    }

    .title-error {
        font-size: 192px;
        font-weight: 700;
    }

    .container-content {
        margin-bottom: 133px;
    }

    .content-error {
        font-size: 27px;
        font-weight: 500;
    }

    .container-link a {
        font-size: 16px;
    }
}