.container-amenagement {
    display: flex;
    & img {
        width: 100%;
        
    }
}

.contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    &-link, &-title {
        display: flex;
        justify-content: center;
        & h2 {
            font-size: 4.5em;
        }
        &-mail {
            font-weight: bold;
            font-size: 2em;
            padding: 34px;
            border: 3px solid black;
            border-radius: 100%;
        }
    }
}