body {
  margin: 40px 0px 0px 0px;
}

.header-container, .main {
  margin: 0px 118px
}


a {
  @include link-decoration-none;
  color: black;
}

a:hover {
  text-decoration: 2px underline $color-secondary-logo;
  text-underline-offset: 4px;
}
