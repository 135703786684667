.main-card {
  margin-top: 50px;
}

.card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 250px;
  &-img {
    width: 50%;
    & img {
      width: 100%;
      border-radius: 40px;
    } 
  }
  &-descriptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 700px;
    & h2 {
      margin: 0;
      font-size: 2.5em;
      font-weight: 600;
    }
    & p , ul {
      font-size: 1.5em;
    }
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  &:nth-last-child(-n + 2) {
  margin-bottom: 100px;
  }
}